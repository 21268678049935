<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-page-header @back="goBack" ></el-page-header>
      <el-breadcrumb-item >我的班级</el-breadcrumb-item>
      <el-breadcrumb-item>{{name}}</el-breadcrumb-item>
      <el-breadcrumb-item>课程详情</el-breadcrumb-item>
    </el-breadcrumb>
    <p class="bold tit">{{datainfo.Subject}} <el-divider direction="vertical"></el-divider>{{datainfo.GradeStart}} <el-divider direction="vertical"></el-divider>{{datainfo.ClassType}}</p>
    <el-table :data="datainfo.classesdetails"
              border
              style="width: 100%"
              :row-class-name="tableRowClassName">
      <el-table-column prop="LessonNo"
                       fixed
                       label="课程讲次"
                       align="center">
      </el-table-column>
      <el-table-column prop="Campus"
                       label="上课地点">
      </el-table-column>
      <el-table-column prop="Address"
                       label="详细地址">
      </el-table-column>
      <el-table-column
                       label="上课时间">
        <template slot-scope="scope">
            <p>{{scope.row.ClassDate}} | {{scope.row.ClassWeek}} | {{scope.row.ClassTime}} </p>
          </template>                       
      </el-table-column>
    </el-table>
    <div class="flex pagination">
      <p class="pn disabd"
         v-if="page==1">首页</p>
      <p v-else
         class="pn"
         @click="handleCurrentChange(1)">首页</p>
      <span @click="handleprev"
            :class="page==1?'disabd':''"> <i class=" el-icon-d-arrow-left"></i>上一页</span>
      <div>
        <span :class="page==index?'color1':''"
              v-for="(item,index) in datainfo.pagearr"
              :key="index"
              @click="handleCurrentChange(index)">
          {{item}}
        </span>
      </div>
      <span @click="handlenext"
            :class="page==totalpage?'disabd':''"><i class=" el-icon-d-arrow-right"></i>下一页</span>
      <p class="pn disabd"
         v-if="page==totalpage">尾页</p>
      <p v-else
         class="pn"
         @click="handleCurrentChange(totalpage)">尾页</p>
    </div>
  </div>
</template>

<script>
import { get_classes_details } from '@/api/classesinfo'
export default {
  name: 'class_detail',
  data () {
    return {
      datainfo: {},
      DBType: '',
      OprId: '',
      activeIndex:'',
      page: 1,
      pagesize: 5,
      totalpage: 1,
      name: ''
    };
  },
  created () {
    var query = sessionStorage.getItem('stu_class_query')? JSON.parse(sessionStorage.getItem('stu_class_query')):{}
    this.DBType = query.DBType || null;
    this.OprId = query.OprId || null;
    this.name = query.activeIndex && query.activeIndex == '0' ? '正在上课的班级' : '历史的班级';
    this.getInit()
  },
  methods: {
    goBack(){
      this.$router.go(-1)
    },
    async getInit () {
      let prames = {
        userid: this.$store.state.user.stu_user_info[0].id,
        DBType: this.DBType,
        OprId: this.OprId,
        page: this.page
      }
      let res = await get_classes_details(prames)
      if (res.flag == '1') {
        this.datainfo = res.datainfo;
        this.totalpage = res.datainfo.totalpage;
        this.page = res.datainfo.currentPage
      } else if (res.flag == '0') {
        this.classesinfo = [];
      }else if(res.flag =='-1'){
        this.$store.dispatch("logout",{
          tips: '登录失效,请重新登录'
        });
      } else {
        this.$alert(res.msg, '提示', {
          confirmButtonText: '确定',
          type: 'error',
        });
      }
    },
    //奇偶数背景色
    tableRowClassName ({ rowIndex }) {
      if (rowIndex % 2 == 0) {
        return 'even';
      } else if (rowIndex % 2 == 1) {
        return 'odd';
      }
      return '';
    },

    handleCurrentChange (val) {
      this.page = val;
      this.getInit()
    },
    handleprev () {
      if (this.page == 1) {
        return false
      }
      this.page = Number(this.page) - 1;
      this.getInit()
    },
    handlenext () {
      if (this.page == this.totalpage) {
        return false
      }
      this.page = Number(this.page) + 1;
      this.getInit()
    }
  },
}
</script>
<style scoped lang="scss">
@import "class.scss";
</style>